import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"

const Contact = () => (
  <Layout headline="It's nice to meet you.">
    <SEO siteTitle="Contact" />
    <article>
    <p>
      I`m glad you found your way to my contact site. 
      Maybe this means you want to get in touch with me. Lucky me. 
      Here are some options you can choose from. <br/> <br/> 
      The coolest way is my Slack account. 
      Just sign up here and use the channel that suits you most. 
      We can discuss any point within a channel or can use direct messages instead.<br/> <br/> 
      The more common way is the contact form. Feel free to use it as well.
    </p>
    <Link to="/">back home</Link>
    </article>
  </Layout>
)

export default Contact
